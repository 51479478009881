<template>
    <div class="products">
        <div v-if="!isProductLoading || is_product" class="products__wrapper container container--small" id="productsList">
            <div class="products__header">
                <h1 class="title title--01" v-if="themeType === 'venture'">Shop</h1>
                <div class="products__header-holder" v-if="is_product || is_filter">
                    <span class="products__header-text"
                        >Showing {{ !pagination.item_count ? 0 : 1 }} - {{ pagination.item_start + pagination.item_count - 1 }} of {{ pagination.item_total }} results</span
                    >
                    <div class="products__header-options">
                        <div class="filters">
                            <span class="filters__label">{{ order_by_label }}</span>
                            <div class="filter__dropdown-overlay" @click="closeSortDropdown" v-if="sort_type"></div>
                            <div class="filter">
                                <div class="filter__btn" @click="openSortDropdown('order')" :class="{ active: sort_type === 'order' || filter.order_by }">
                                    <Icon name="order_icon" />
                                </div>
                                <div class="filter__dropdown" v-if="sort_type === 'order'">
                                    <div class="filter__dropdown-item" @click="orderBy(item.id)" v-for="item in order_by" :class="{ active: filter.order_by === item.id }" :key="item.id">
                                        {{ item.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="filter">
                                <div class="filter__btn" @click="openSortDropdown('filter')" :class="{ active: sort_type === 'filter' || filter.product_category_ids.length }">
                                    <Icon name="filter_icon" />
                                </div>
                                <div class="filter__dropdown" v-if="sort_type === 'filter'">
                                    <div class="filter__dropdown-title">
                                        <span>Product Categories</span>
                                    </div>
                                    <div
                                        class="filter__dropdown-item"
                                        @click="filterByCategories(item.id)"
                                        v-for="item in store_categories"
                                        :class="{ active: filter.product_category_ids.includes(item.id) }"
                                        :key="item.id"
                                    >
                                        {{ item.name }} ({{ item.usage_count }})
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="categories-wrap">
                            <div class="categories-wrap__list" v-if="filter.product_category_ids.length">
                                <div class="categories-wrap__item" v-for="item in filter.product_category_ids" :key="item" @click="filterByCategories(item)">
                                    <span class="label-wrap">{{ store_categories_hash[item] }}</span> <span class="ico-wrap"><Icon name="cross" /></span>
                                </div>
                            </div>
                            <div class="categories-wrap__second-list" v-if="filter.search">
                                <div class="categories-wrap__item" @click="clearSearch()">
                                    <span class="label-wrap">Search: {{ filter.search }}</span> <span class="ico-wrap"><Icon name="cross" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyState v-if="!is_product && !isProductLoading" icon="fileIcon" :is_button="is_filter" text_button="Clear filter" @onClick="clearFilter" />
            <div v-else class="products__list">
                <ProductCard
                    v-for="product in products"
                    :key="product.id"
                    v-bind="product"
                    :currency="storeSettings?.currency"
                    :is_in_favorite="checkProductIsFavorite(product.id)"
                    :is_disabled="(product.type === 'simple' && checkProductsIsDisabled(product.id)) || !product.in_stock"
                    :label="product.type === 'variable' && !product.is_has_editable_text_area ? 'Select options' : product.is_has_editable_text_area ? 'Personalise' : ''"
                    @onAddToCart="onAddToCart(product)"
                    @onFavoriteClick="handleProductToFavorite({ ...product, is_in_favorite: checkProductIsFavorite(product.id) })"
                />
            </div>
            <AutoLoader
                v-if="!is_loading && !previewMode && ((!isProductLoading && products.length) || products.length < pagination.item_total)"
                @onNextBatch="onNextBatch"
                :pagination-data="pagination"
                :isLoading="isProductLoading"
            />
        </div>
        <Preloader :show="is_loading || isProductLoading" :is_static="true" />
    </div>
    <About />
    <Carousel
        :heading="carousel_data.heading"
        :items_type="carousel_data.items_type"
        v-if="carousel_data.items && carousel_data.items.length"
        :items="carousel_data.items"
        @onAddToCart="onAddToCart"
    />
</template>

<script src="./ShopView.js"></script>
<style src="./ShopView.scss" lang="scss"></style>
