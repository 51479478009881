<template>
    <div class="banner" :class="{ 'banner--small': !bannerSource, 'banner--orbit': isOrbit, 'banner--venture': isVenture }">
        <div class="banner__bg" :class="{ 'banner__bg--venture': isVenture, 'banner__bg--orbit': isOrbit }" v-if="bannerSource">
            <img :src="bannerSource" alt="banner" />
            <div v-if="(isVenture || isOrbit) && Object.values(social_links).some((item) => item)" :class="`banner__icons banner__icons--${themeType}`">
                <template v-for="(url, icon_name) in social_links" :key="icon_name">
                    <SocialLink v-if="url" :name="icon_name" :url="url" />
                </template>
            </div>
        </div>
        <div v-if="isVenture" :class="`banner__content banner__content--${themeType}`">
            <div class="banner__content-title title--01" v-if="title">{{ title }}</div>
            <CustomButton :text="'Shop now'" @onClick="scrollToElement" />
            <div class="banner__arrow" @click="scrollToElement">
                <Icon name="arrow_to_bottom" />
            </div>
        </div>

        <div v-if="isOrbit" :class="`banner__content banner__content--${themeType}`">
            <router-link v-if="logoURL" class="banner__content-logo" to="/shop">
                <img :src="logoURL || store_settings.store_logo_image" :alt="store_settings.store_name" />
            </router-link>
            <div class="banner__content-title title--03">{{ title }}</div>
        </div>
    </div>
</template>

<script src="./banner.js"></script>
<style lang="scss" src="./banner.scss" />
