<template>
    <div class="cart">
        <EmptyState v-if="!items_in_cart.length" icon="emptyCart" text="No products in bag" text_button="Back to shop" @onClick="handleClick" />
        <div v-else class="cart__wrapper container">
            <div class="cart__list">
                <div class="cart__header">
                    <div class="cart__header-column">Your bag ({{ available_products_count }})</div>
                    <div class="cart__header-column"></div>
                    <div class="cart__header-column">Quantity</div>
                    <div class="cart__header-column">Price</div>
                    <div class="cart__header-column">Total</div>
                    <div class="cart__header-column"></div>
                </div>
                <EmptyState v-if="!available_products.length" icon="emptyCart" :is_button="false" text="No available products" />
                <ProductCardHorizontal v-else v-for="item in available_products" :key="item.product.id" :item="item" />
            </div>
            <div v-if="unavailable_products.length" class="cart-list-unavailable">
                <div class="cart__header">Unavailable ({{ unavailable_products_count }})</div>
                <ProductCardHorizontal v-for="item in unavailable_products" :key="item.product.id" :item="item" :unavailable="true" />
            </div>
            <div class="cart-summary">
                <h3 class="cart-summary__title title--03">Bag total</h3>
                <div class="cart-summary__wrapper">
                    <div class="cart-summary__content">
                        <div class="cart-summary__subtotal">
                            <div class="cart-summary__label">Subtotal</div>
                            <div class="cart-summary__price">
                                {{ store_settings.currency.symbol }}<span>{{ parseCost(subtotal) }}</span>
                            </div>
                        </div>
                        <div class="cart-summary__shipping">
                            <div class="cart-summary__label">Shipping</div>
                            <div class="cart-summary__options">
                                <div class="cart-summary__option">
                                    <div class="cart-summary__radio-btn" :class="{ 'cart-summary__radio-btn--active': is_standard_shipping }" @click="standardShipping"></div>
                                    <div class="cart-summary__option-text">
                                        {{ store_settings.currency.code === "GBP" ? "Standard up to 5 Working Days" : "Standard up to 7 Working Days" }}
                                    </div>
                                </div>
                            </div>
                            <div class="cart-summary__price">
                                {{ store_settings.currency.symbol }}<span>{{ parseCost(shipping_exchange_map[store_settings.currency.code]) }}</span>
                            </div>
                        </div>
                        <div class="cart-summary__total">
                            <div class="cart-summary__total-label">Total</div>
                            <div class="cart-summary__selling-price">
                                <span class="cart-summary__total-price">
                                    <!--                                    <span class="cart-summary__vat">(includes {{ store_settings.currency.symbol }}2.00 VAT)</span>-->
                                    {{ store_settings.currency.symbol }}<span>{{ parseCost(round(subtotal + shipping)) }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-actions">
                <div class="cart-actions__cards"></div>
                <div class="cart-actions__buttons">
                    <router-link to="/shop" class="cart-actions__btn">
                        <CustomButton text="Shopping" />
                    </router-link>
                    <div class="cart-actions__btn" :class="{ 'cart-actions__btn--disabled': unavailable_products.length }" @click="checkout()">
                        <CustomButton text="Checkout" @onClick="$router.push('/checkout')" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <Carousel
                :heading="carousel_data.heading"
                :items_type="carousel_data.items_type"
                v-if="carousel_data.items && carousel_data.items.length"
                :items="carousel_data.items"
                @onLinkClick="openFavouriteProduct"
                @onAddToCart="onAddToCartFromFavorite"
            />
        </div>
    </div>
</template>

<script src="./CartView.js" />
<style lang="scss" scoped src="./CartView.scss" />
