import OrbitFooter from "./variants/orbit/orbitFooter.vue";
import StrideFooter from "./variants/stride/strideFooter.vue";
import VentureFooter from "./variants/venture/ventureFooter.vue";
import footerMixin from "@/components/shared/footer/footer.mixin.js";

export default {
    name: "Footer",
    mixins: [footerMixin],
    components: {
        StrideFooter,
        VentureFooter,
        OrbitFooter,
    },
    props: {
        theme: {
            type: String,
            default: "",
        },
    },
};
