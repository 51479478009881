import productCardMixin from "./productCard.mixin.js";
import VentureProductCard from "./variants/ventureProductCard/ventureProductCard.vue";
import StrideProductCard from "./variants/strideProductCard/strideProductCard.vue";
import OrbitProductCard from "@/components/shared/productCard/variants/orbitProductCard/orbitProductCard.vue";

export default {
    name: "ProductCard",
    components: { VentureProductCard, StrideProductCard, OrbitProductCard },
    mixins: [productCardMixin],
};
