import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import ProductCard from "@/components/shared/productCard/productCard.vue";
import { Autoplay } from "swiper/modules";
import { mapActions, mapGetters } from "vuex";
import CartService from "@/services/cart.service";

const cartService = new CartService();

export default {
    name: "Carousel",
    components: {
        Swiper,
        SwiperSlide,
        ProductCard,
    },
    props: {
        heading: {
            type: String,
            defaultValue: "",
        },
        items_type: {
            type: String,
            defaultValue: "favourite",
        },
        reload_page: {
            type: Boolean,
            defaultValue: false,
        },
    },
    data: () => ({
        items: [],
        store_products_available: [],
        store_products_unavailable: [],
    }),
    methods: {
        ...mapActions("products", [
            "setProductToFavorite",
            "fetchAvailableProducts",
            "removeProductFromFavorite",
            "fetchFavoriteProducts",
            "setProductToCart",
            "updateProductInCart",
            "updateFavoriteProducts",
        ]),
        async onAddToCart(prod) {
            const { product, count, is_available } = this.products_in_cart_hash[prod.id] || {};

            if (this.products_in_cart_hash[prod.id]) {
                await this.updateProductInCart({ product, count: count + 1, is_available });
            } else {
                await this.setProductToCart({ product: prod });
            }

            this.$notify({
                title: "Product was added to bag!",
                type: "success",
            });
        },
        async onRemove(product) {
            await this.removeProductFromFavorite(product.id);
        },
        checkProductIsFavorite(id) {
            return this.favoriteProducts.map(p => p.id).includes(id);
        },
        Autoplay,
        async handleFavoriteClick(product) {
            this.checkProductIsFavorite(product.id) ? await this.removeProductFromFavorite(product.id) : await this.setProductToFavorite(product);
        },
        onLinkClick() {
            this.$emit("onLinkClick");
        },
        checkAllItems() {
            const store_products_available = this.store_products_available;
            const store_products_unavailable = this.store_products_unavailable;

            this.items = this.favoriteProducts.reduce((acc, current) => {
                const the_same_product_from_response = store_products_available.find(sp => sp.id === current.id);
                const not_available_store_product = store_products_unavailable.find(sp => sp.id === current.id);

                if (the_same_product_from_response) {
                    acc.push({
                        ...current,
                        ...the_same_product_from_response,
                        store_product_image_path: the_same_product_from_response.store_product_image_url,
                        product_name: the_same_product_from_response.store_product_name,
                        is_available: true,
                    });
                } else if (not_available_store_product?.store_product_image_url) {
                    acc.push({
                        ...current,
                        store_product_image_path: not_available_store_product?.store_product_image_url,
                        is_available: false,
                        is_deleted: not_available_store_product?.is_deleted,
                        in_stock: not_available_store_product?.in_stock,
                    });
                } else if (!not_available_store_product) {
                    const current_modified = current.store_product_image_url ? { ...current, store_product_image_path: current.store_product_image_url } : current;
                    acc.push({ ...current_modified, is_available: true });
                }
                return acc;
            }, []);
        },
        async handleProducts() {
            const parsed_favorite_product_list = this.favoriteProducts.map(({ id, combination, store_product_variant_id }) => ({
                id,
                combination: combination || null,
                store_product_variant_id: store_product_variant_id || null,
            }));
            const {
                data: { store_products_available, store_products_unavailable },
            } = await cartService.postProducts({ store_products: parsed_favorite_product_list });

            this.store_products_available = store_products_available;
            this.store_products_unavailable = store_products_unavailable;
        },
        async initialSetup() {
            if (this.items_type === "related") {
                this.items = this.related_products;
            } else {
                await this.fetchFavoriteProducts();
                await this.handleProducts();
                this.checkAllItems();
                await this.updateFavoriteProducts(this.items);
            }
        },
    },
    emits: {
        onAddToCart: () => null,
    },
    computed: {
        ...mapGetters("products", {
            favoriteProducts: "getFavoriteProducts",
            products_in_cart_hash: "getProductsInCartHash",
            related_products: "getRelatedProducts",
        }),
        ...mapGetters("storeProps", {
            storeSettings: "getStoreSettings",
            themeType: "getThemeType",
        }),
    },
    watch: {
        async favoriteProducts() {
            if (this.items_type === "favourite") {
                await this.handleProducts();
                this.checkAllItems();
            }
        },
    },
    async mounted() {
        await this.initialSetup();
        this.is_loading = false;
    },
};
