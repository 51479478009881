<template>
    <div class="terms-and-condition">
        <div class="terms-and-condition__wrapper">
            <h2 class="terms-and-condition__title title--02">Terms and Conditions</h2>
            <div class="terms-and-condition__content">
                <div class="terms-and-condition__content-column terms-and-condition__content-column-left">
                    <div class="entry-content" itemprop="mainContentOfPage" itemscope="" itemtype="https://schema.org/WebPageElement">
                        <p>This store harnesses the creativity of the seller offering their unique merchandise collection, but is powered by technology provided from Merchr.</p>
                        <ol>
                            <li>
                                These terms and conditions (‘the Terms’) govern the users (‘you’ or ‘your’) use of this site (‘the Website’) and your relationship with Merchr Ltd and/or Merchr
                                Holdings LLC (‘merchr’,’we’, ‘our’ or ‘us’). Please read them carefully as they affect your rights and liabilities under law. If you do not agree to these Terms, please
                                do not access nor use the Website. If you have any questions regarding these Terms then please contact us.
                            </li>
                            <li>
                                <strong>Agreement</strong>
                                <ul>
                                    <li>When you place an order for a Product by using the website you are offering to buy it for the price shown on the website, subject to these Terms.</li>
                                    <li>
                                        Our online order process allows you to check and amend any errors before submitting your order to us. Please take the time to read and check your order on each
                                        page of the order process.
                                    </li>
                                    <li>
                                        When you place your order online we will take payment as per the clause. We will check Product availability and contact you by email to confirm that your order
                                        has been accepted. This email is our acceptance of your order and will specify delivery details and confirm the price of the Products purchased.
                                    </li>
                                    <li>
                                        If the Product is not available we will decline your order and send you an email explaining this and any payment made for the Products will be refunded in full.
                                    </li>
                                    <li>
                                        Please be aware that once the following takes place:
                                        <ul>
                                            <li>you have successfully placed an order;</li>
                                            <li>we have accepted it;</li>
                                            <li>it has been received by our warehouse.</li>
                                            <li>we are unable to amend the order or details provided. Please note that this does not affect your right to cancel a contract.</li>
                                        </ul>
                                    </li>
                                    <li>You should print a copy of these Terms or save them to your computer for future reference.</li>
                                    <li>
                                        We may cancel any contract between you and us and not supply Products if it is reasonable to do so and may change or discontinue the availability of Products at
                                        any time at its sole discretion. If we cancel your order, any payment made for the Products will be refunded in full.
                                    </li>
                                    <li>Each order that we accept will be a separate contract between you and us and each contract will be subject to these Terms.</li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="3">
                            <li>
                                <strong>Product Price(s)</strong>
                                <ul>
                                    <li>
                                        The price for each Product is shown on the website and includes VAT at the current rate for orders shipping within the UK. For orders shipping outside the UK
                                        you are responsible for any import duties, custom duties or taxes.
                                    </li>
                                    <li>
                                        We always try to make sure that the prices on the website are accurate but errors may occur. If we discover an error in the pricing of the Products that you
                                        order we will notify you as soon as possible using the contact details you supplied to us when placing your order. We will then give you the option of
                                        reconfirming your order at the correct price or cancelling it. If we are unable to reach you within 7 days of our notice we will treat the order as cancelled.
                                        If you cancel, any payment made for the Products will be refunded in full. If you confirm, you must pay the difference before we despatch the Product.
                                    </li>
                                    <li>You will be notified of the delivery costs automatically before you submit your credit card details, based on the delivery address you specify.</li>
                                    <li>
                                        Prices are quoted on the website in UK Pound Sterling (£), US Dollars ($) or Euros (€). Your credit card company may charge their exchange rate where the
                                        payment currency differs from your account. Please note that refunds are made in the same currency as the original order, and we cannot be held responsible for
                                        any loss due to exchange rate fluctuations.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="4">
                            <li>
                                <strong>Payment</strong>
                                <ul>
                                    <li>You must provide full and accurate payment details (e.g. credit card, debit card, PayPal or gift voucher) at the point of submitting your order.</li>
                                    <li>
                                        We will take payment from your credit card, debit card, PayPal account or gift voucher as soon as you place your order, except where products are out of stock
                                        or on pre-order. Payment will be taken for these orders when the product is ready for dispatch.
                                    </li>
                                    <li>We currently accept VISA, MasterCard, Visa Electron, Paypal and American Express and may use third-party payment processors such as Stripe.</li>
                                    <li>
                                        To ensure that shopping online is secure, when paying by credit or debit card your details will be encrypted to minimise the possibility of someone being able
                                        to read them as they are sent over the internet. Your credit card company may also carry out additional security checks to confirm it is you making the order.
                                        In the event, your card is declined please contact your card issuer to authorise the transaction.
                                    </li>
                                    <li>All order details will be sent to the credit card billing address and not the delivery address if the name of the recipient of the order is different.</li>
                                    <li>
                                        Where payment is made by you using methods other than Paypal, Visa/MasterCard or American Express there may be a delay to your order while we verify your age.
                                        Orders may also be subject to delay should we need to carry out additional security checks. Security searches may include: Sharing your data with a third party
                                        for age verification purposes, Electoral Roll, credit check, Experian FraudID, and bank – name and address checks.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="5">
                            <li>
                                <strong>Gift Vouchers</strong>
                                <ul>
                                    <li>Gift vouchers must be redeemed within 1 year of purchase and cannot be set off against Products that have already been paid for.</li>
                                    <li>If the value of the order exceeds the value of a gift voucher, the balance must be paid by credit or debit card, or via PayPal.</li>
                                    <li>
                                        Unused balances will be held within the recipient’s voucher for the remainder of the 1 year referred to in clause 5. At the end of that 1 year, the voucher (and
                                        any credit remaining on it) will expire.
                                    </li>
                                    <li>Gift vouchers have a cash redemption value of 0.001p.</li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="6">
                            <li>
                                <strong>Promotional Voucher Terms</strong>
                                <ul>
                                    <li>If you have received a promotional voucher from us, only one such promotional voucher code can be redeemed per order.</li>
                                    <li>
                                        Promotional voucher codes are issued from time to time at our discretion. We reserve the right to cancel promotional voucher codes at any time without notice
                                        and without giving a reason.
                                    </li>
                                    <li>
                                        Only one promotional voucher code can be redeemed against any single order. If there is an unused balance of discount or credit remaining on the promotional
                                        voucher code, that balance will be extinguished and cannot be redeemed with any other orders.
                                    </li>
                                    <li>Promotional voucher codes cannot be re-used.</li>
                                    <li>Promotional voucher codes have a cash redemption value of 0.001p and are not transferable.</li>
                                    <li>Promotional voucher codes may not be used in conjunction with any other offer, discount or promotion.</li>
                                    <li>Promotional voucher codes may not be sold. For the avoidance of doubt, this includes auctions and other online sales.</li>
                                    <li>Certain Products cannot be purchased with promotional voucher codes. Such Products are clearly marked on their respective product pages.</li>
                                    <li>The issuing of promotional voucher codes may be restricted (for example, one per email address). Please see the promotional page for details.</li>
                                    <li>Our promotional voucher codes cannot be used to purchase regular gift vouchers.</li>
                                    <li>
                                        We offer a 10% discount for certain orders above a nominated amount in £ (or equivalent in other currencies) to first-time email subscribers. Discounts are not
                                        cumulative and therefore cannot be used in conjunction with any other offer/discount/voucher.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="7">
                            <li>
                                <strong>Products</strong>
                                <ul>
                                    <li>
                                        All Products are available while stocks last. If we are unable to supply a Product to you following our acceptance of your order, we will notify you as soon as
                                        we can. If we cannot supply a Product you will not be charged for it and we will refund or re-credit your account with the amount of your payment.
                                    </li>
                                    <li>
                                        The images of the Products on the website are for illustrative purposes only. Although we have made every effort to display the images accurately, we cannot
                                        guarantee that your computer’s or device’s display accurately reflect the Products. Your Products may vary from those images.
                                    </li>
                                    <li>
                                        Certain Products advertised on the website (including but not limited to alcoholic beverages and dangerous items like knives) can only be purchased if you
                                        satisfy the legal age requirement for that Product. We are not allowed by law to supply these Products to you if you do not satisfy these age requirements. If
                                        you are underage, please do not attempt to order these Products. We reserve the right to request proof of age and not to supply Products to users that we
                                        suspect do not meet the legal age requirements.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="8">
                            <li>
                                <strong>Personalised/Photo Upload Products</strong>
                                <ul>
                                    <li>
                                        We will use the information and images that you provide to create your customised product/s. We will not share that information with any third party, except as
                                        is strictly necessary to fulfil your order, e.g. to ship an order or if printed and dispatched by an external supplier.
                                    </li>
                                    <li>
                                        To create your customised products, we save a copy of the images used to compose the customisations. We do not share your images and at all times they remain
                                        your property. Any images uploaded must be your own and by uploading them you are confirming that you have the right to use them. We are not responsible for the
                                        content of the information provided.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <ol start="9">
                            <li>
                                <strong>Delivery</strong>
                                <ul>
                                    <li>
                                        We aim to deliver your Products by the estimated delivery date set out in the acceptance email and any event within 30 days from the date of order or, in the
                                        case of pre-ordered Products, 30 days from the date of the despatch confirmation email. If we are unable to meet the estimated delivery date because of an event
                                        outside our reasonable control we will email to you a revised estimated delivery date.
                                    </li>
                                    <li>Delivery will be completed when we deliver the Products to the delivery address given in the order.</li>
                                    <li>The Products will be your responsibility from the completion of delivery.</li>
                                    <li>
                                        You own the Products once we have received payment in full, including all applicable delivery charges. You will be notified of the delivery charges when you
                                        place your order.
                                    </li>
                                    <li>
                                        Delivery outside the UK may be subject to local import duties, custom duties or taxes which are your responsibility where they apply. Unfortunately, we cannot
                                        advise you what these charges will be and we are not responsible for them.
                                    </li>
                                    <li>
                                        If you do not pay these local import/custom duties or taxes then the Product could be returned to Us or possibly destroyed if too expensive to return. Please
                                        note, if this happens, we may not be able to refund your purchase. You must comply with all applicable laws and regulations of the country for which the
                                        Products are destined. We will not be liable or responsible if you are in breach of any law in any such country.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="terms-and-condition__content-column terms-and-condition__content-column-right">
                    <ol start="10">
                        <li>
                            <strong>Copyright and Trademarks</strong>
                            <ul>
                                <li>We do not give you any right or interest in any copyright or intellectual property rights in any Product.</li>
                                <li>
                                    The copyright and other intellectual property rights in the website and any information, images or software accessed via the website are owned by or licensed to us
                                    and, unless otherwise specified, may not be used, copied or altered without our express consent.
                                </li>
                                <li>Copyright extends to the design, look and feel of the website, all photographs on it and our marketing materials.</li>
                                <li>We do not give any warranty or representation in respect of the trademarks or trade names of any of the Products or brands featured on the website.</li>
                                <li>
                                    We do not give you any right or interest in any copyright or intellectual property rights in any Product nor the right to copy them, we does not make any warranty
                                    or representation in respect of any other trademark and the trademarks of the brands featured on this Website. belong to their respective owners.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <ol start="11">
                        <li>
                            <strong>Privacy and Data Policy</strong>
                            <ul>
                                <li>We commit to protecting your privacy and promises only to use information collected about you following our Privacy Policy.</li>
                            </ul>
                        </li>
                    </ol>
                    <ol start="12">
                        <li>
                            <strong>Returns/Refunds</strong>
                            <ul>
                                <li>
                                    As all of our orders are printed on demand especially for you, unfortunately we do not offer returns unless the item is faulty or does not meet the description.
                                </li>
                                <li>
                                    We hope that you will be satisfied with your Products but there may be occasions where you feel it necessary to return an item. We aim to keep the process as simple
                                    as possible.
                                </li>
                                <li>We will try to attend to all return requests as soon as practically possible.</li>
                                <li>Please note that returns are not able to be accepted in person at our registered office address.</li>
                                <li>You may be required to return your item to us in order to receive your refund. You will be notified if this is the case during the returns process.</li>
                                <li>
                                    If you return an order, we will reimburse you the price of the order including the costs of delivery (except for the supplementary costs arising if you chose a type
                                    of delivery other than the least expensive type of standard delivery offered by us). If you cancel only part of an order, we will reimburse you that part of the
                                    price which relates to the returned Products.
                                </li>
                                <li>
                                    We may make a deduction from the amount due to you which reflects the loss in value of any Products supplied if the loss is the result of unnecessary handling or
                                    damage caused by you.
                                </li>
                                <li>We will make the repayment without undue delay and not later than:</li>
                            </ul>
                        </li>
                    </ol>
                    <ul>
                        <li style="list-style-type: none">
                            <ul>
                                <li style="list-style-type: none">
                                    <ul>
                                        <li>14 days after the day we receive back from you any Products supplied, or</li>
                                        <li>(if earlier) 14 days after the day you provide evidence that you have returned the Products.</li>
                                    </ul>
                                </li>
                                <li>
                                    We will refund the price of the returned Products using the same means of payment as was used to pay for the order unless you and we have expressly agreed
                                    otherwise. You will not incur any fees from us as a result of the refund.
                                </li>
                                <li>
                                    At our request, we (or our nominated courier service) may collect the Products from you. Otherwise, you shall send back the Products or hand them over to us without
                                    undue delay and in any event not later than 14 days from the day on which you communicate your return to us. The deadline is met if you send back the Products on or
                                    before the expiry of the 14 days.
                                </li>
                                <li>
                                    As a consumer, you have legal rights concerning Products that are faulty or not as described. Advice about your legal rights is available from your local Citizens’
                                    Advice Bureau or Trading Standards office. Nothing in these Terms will affect these legal rights.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ol start="14">
                        <li>
                            <strong>RETURNS PROCESS</strong>
                            <ul>
                                <li>To return an item, please notify us using the following details:</li>
                            </ul>
                        </li>
                    </ol>
                    <ul>
                        <li>Email: hello@merchr.com;</li>
                        <li>
                            We may allocate you a returns code which should be included with the package so that we can process your return.
                            <ul>
                                <li>To return the Products following clause 12, in the first instance please contact our customer service team at hello@merchr.com.</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>You will be responsible for the returned Products until they reach us, you should retain your proof of postage.</li>
                    </ul>
                    <ol start="15">
                        <li>
                            <strong>&nbsp;Liabilities</strong>
                            <ul>
                                <li>
                                    We only supply the Products for domestic and private use. You agree not to use the Product for any commercial, business or re-sale purposes, and we have no
                                    liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity
                                </li>
                                <li>
                                    We do not in any way exclude or limit our liability for:
                                    <ul>
                                        <li>death or personal injury caused by our negligence;</li>
                                        <li>fraud or fraudulent misrepresentation;</li>
                                        <li>any breach of the terms implied by section 12 of the Sale of Goods Act 1979 (title and quiet possession);</li>
                                        <li>
                                            any breach of the terms implied by section 13 to 15 of the Sale of Goods Act 1979 (description, satisfactory quality, fitness for purpose and samples); and
                                            defective products under the Consumer Protection Act 1987.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    We will not be liable in any amount for failure to perform or delay in performance of any obligation under a contract if such failure is caused by the occurrence of
                                    any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, industrial action, fire, flood, war or act
                                    of God.
                                </li>
                                <li>
                                    Please read the instructions, manuals and other user documentation that comes with your Products carefully. We are not involved in the manufacture of Products and
                                    will not advise on their use or operation or the manufacturer’s guidelines. We recommend that you use all Products safely and follow the manufacturer’s guidelines.
                                </li>
                                <li>
                                    Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby
                                    excluded to the maximum extent permitted by law.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <ol start="16">
                        <li>
                            <strong>Responsibilities</strong>
                            <ul>
                                <li>
                                    We have tried to make the descriptions (including all pictures) of the Products as accurate as possible but you should check the dimensions stated on the website or
                                    contact us for exact sizes.
                                </li>
                                <li>We shall insure ordered Products at their retail value whilst being delivered to you. We will not cover any subsequent loss or destruction following delivery.</li>
                                <li>
                                    Because of the nature of the internet, we provide and maintain the website on an “as is” and “as available” basis and makes no promise that the use of the website
                                    will be uninterrupted or error-free. We are not responsible to you if we are unable to provide our internet services for any reason beyond our control.
                                </li>
                                <li>
                                    Our website contains links to other websites which are not under our control or maintained by us. These links are provided for your convenience only and we are not
                                    responsible for the content of those sites.
                                </li>
                                <li>
                                    You will be responsible for any breach of these Terms by you and if you use the website in breach of these Terms you will be liable to and will reimburse us for any
                                    loss or damage caused as a result.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <ol start="17">
                        <li>
                            <strong>General Terms</strong>
                            <ul>
                                <li>
                                    We may revise these Terms from time to time. Any changes to our Terms will be notified on the website. You must check these Terms whenever you place an order with
                                    us.
                                </li>
                                <li>We may transfer our rights and obligations under a Contract to another organisation, but this will not affect your rights or our obligations under these Terms.</li>
                                <li>
                                    You may only transfer your rights or our obligations under these Terms to another person if we agree in writing. However if you have purchased a Product as a gift,
                                    you may transfer the benefit of any warranties we give to you under this contract to the recipient of the gift without needing to ask our consent.
                                </li>
                                <li>
                                    This contract is between you and us. No other person shall have any rights to enforce any of its terms, whether under the Contracts (Rights of Third Parties Act)
                                    1999 or otherwise.
                                </li>
                                <li>If we do not enforce any provision of this agreement such will not be considered a continuing waiver.</li>
                                <li>
                                    Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining
                                    paragraphs will remain in full force and effect.
                                </li>
                                <li>
                                    If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that
                                    will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations.
                                </li>
                                <li>If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</li>
                                <li>
                                    If any part of these Terms is held to be unenforceable, such part will at our option be construed as far as possible to reflect the parties intentions and the
                                    remainder of the provisions will remain in full force and effect.
                                </li>
                                <li>
                                    These Terms are governed by English law. This means a contract for the purchase of Products through us and any dispute or claim arising out of or in connection with
                                    it will be governed by English law. You and I both agree that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident
                                    of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are a resident of Scotland, you may also bring proceedings in Scotland.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <ol start="18">
                        <li>
                            <strong>Notices</strong>
                            <ul>
                                <li>All notices shall be given to us by email or post.</li>
                                <li>
                                    All notices sent by email will be deemed to have been received on the day that they are sent or, if sent on a national holiday in England or on a Saturday or
                                    Sunday, the next working day following the day on which the email was sent. All notices sent by post will be deemed to have been received 3 working days after the
                                    date of posting.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <ol start="19">
                        <li>
                            <strong>Contacting Us</strong>
                            <ul>
                                <li>
                                    If you would like help when ordering, information about a product, a request for a Product or if you would just like to provide us with feedback on our service,
                                    please email us at hello@merchr.com.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./TermsAndCondition.js"></script>
<style lang="scss" scoped src="./TermsAndCondition.scss" />
