<template>
    <div class="contact-us-form">
        <Preloader :show="is_loading" />
        <div
            class="contact-us-form__field"
            :class="{
                'contact-us-form__field--error': check_is_valid_field(field_name) || (field_name === 'email' && v$.formData.email.emailValidation.$invalid),
            }"
            v-for="{ field_name, label } in fields"
            :key="field_name"
        >
            <label class="contact-us-form__label" :for="field_name">{{ label }}</label>
            <span class="contact-us-form__error">{{ field_name === "email" && v$.formData.email.emailValidation.$invalid ? "Enter a valid email." : "This field is required." }}</span>
            <input
                class="contact-us-form__input"
                :id="field_name"
                :name="field_name"
                type="text"
                :maxlength="limit"
                v-model="formData[field_name]"
                v-if="field_name !== 'message'"
                @input="e => onInput(field_name, e.target.value)"
            />
            <textarea class="contact-us-form__input" v-else :maxlength="limit" :id="field_name" :name="field_name" v-model="formData.message" @input="e => onInput(field_name, e.target.value)" />
        </div>
        <div v-if="!with_order_id">
            <div class="contact-us-form__radio-row">
                <div class="contact-us-form__radio-col" @click="handleEnquiry('general')">
                    <div class="custom-radio" :class="{ 'custom-radio_active': enquiry === 'general' }" ><span> </span></div>
                    <div>General Enquiry</div>
                </div>
                <div class="contact-us-form__radio-col"  @click="handleEnquiry('order')">
                    <div class="custom-radio" :class="{ 'custom-radio_active': enquiry === 'order' }"><span></span></div>
                    <div>Order Enquiry</div>
                </div>
            </div>
        </div>
        <div class="contact-us-form__field contact-us-form__field--btn">
            <CustomButton :text="buttonText || 'Submit'" @onClick="submitContacts" />
        </div>
    </div>
</template>
<script src="./contactUsForm.js" />
<style src="./contactUsForm.scss" scoped lang="scss" />
