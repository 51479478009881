import { mapGetters } from "vuex";
import Icon from "@/components/ui/icon/icon.vue";

export default {
    components: { Icon },
    props: {
        store_product_image_path: {
            type: String,
            defaultValue: "",
        },
        product_name: {
            type: String,
            defaultValue: "",
        },
        selling_price: {
            type: Number,
            defaultValue: 0,
        },
        label: {
            type: String,
            defaultValue: false,
        },
        is_in_favorite: {
            type: Boolean,
            defaultValue: false,
        },
        currency: {
            type: Object,
            defaultValue: { symbol: "£" },
        },
        id: {
            type: Number,
        },
        description: {
            type: String,
        },
        is_disabled: {
            type: Boolean,
            defaultValue: false,
        },
        cost: {
            type: Number,
            defaultValue: 0,
        },
        variants_count: {
            type: Number,
            defaultValue: 0,
        },
        is_has_editable_text_area: {
            type: Number,
            defaultValue: 0,
        },
        part_url: {
            type: String,
            defaultValue: "",
        },
        type: {
            type: String,
            defaultValue: "simple",
        },
        editable_text_areas: {
            type: Number,
            defaultValue: 0,
        },
        sku: {
            type: String,
            defaultValue: "",
        },
        store_product_variant_id: null,
        personalised_text: {
            type: Object,
            defaultValue: {},
        },
        combination: "",
        is_not_active: {
            type: Boolean,
            defaultValue: false,
        },
        reload_page: {
            type: Boolean,
            defaultValue: false,
        },
        in_stock: {
            type: Number,
            defaultValue: 1,
        },
        currency_id: {
            type: [String, Number],
            default: null,
        },
        currency_symbol: {
            type: String,
            default: "",
        },
        is_available: {
            type: Boolean,
            defaultValue: 1,
        },
        is_deleted: {
            type: Number,
            defaultValue: 0,
        },
        store_product_image_url: {
            type: String,
            defaultValue: "",
        },
        option_values_names: Array,
        store_product_name: String,
        product_image_url: String,
    },
    data: () => ({
        encodedProductName: "",
    }),
    emits: {
        onFavoriteClick: null,
        onAddToCart: null,
        onLinkClick: null,
    },
    methods: {
        handleFavoriteClick() {
            this.$emit("onFavoriteClick");
        },
        handleAddToCart() {
            if (this.is_not_active) return;
            if (this.type === "variable" || this.is_has_editable_text_area) {
                if (this.reload_page) {
                    this.$emit("onLinkClick");
                    setTimeout(() => {
                        location.reload();
                    }, 0);
                }
                this.openPage("/product/" + this.part_url);
                return;
            }
            this.$emit("onAddToCart");
        },
        onLinkClick() {
            if (this.reload_page) {
                setTimeout(() => {
                    location.reload();
                }, 0);
            }
            this.$emit("onLinkClick");
        },
        openPage(url) {
            this.$router.push(url);
        },
    },
    computed: {
        ...mapGetters("storeProps", { themeType: "getThemeType" }),
    },
    mounted() {
        this.encodedProductName = encodeURIComponent(this.product_name);
    },
};
