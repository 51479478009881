import { mapGetters } from "vuex";

export default {
    name: "CustomButton",
    props: {
        text: {
            type: String,
            defaultValue: "",
        },
        is_disabled: {
            type: Boolean,
            defaultValue: false,
        },
    },
    emits: {
        onClick: null,
    },
    methods: {
        handleClick() {
            this.$emit("onClick");
        },
    },
    computed: {
        ...mapGetters("storeProps", { themeType: "getThemeType" }),
    },
};
