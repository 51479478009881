<template>
    <div class="error">
        <div class="error__content">
            <div class="error__title">Error</div>
            <div class="error__number">404</div>
            <div class="error__subtitle">Oops! Page not found</div>
            <p>
                <span>The page you are looking for does not exist.</span>
                <br />
                <span>Click the button below to go back to the homepage.</span>
            </p>
            <div class="error__btn">
                <CustomButton text="Back to home" @onClick="$router.push('/shop')" />
            </div>
        </div>
    </div>
</template>

<script src="./ErrorView.js" />
<style lang="scss" src="./ErrorView.scss" />
