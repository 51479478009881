<template>
    <router-view />
    <notifications position="top right" />
</template>

<script>
import { Notifications } from "@kyvg/vue3-notification";

export default {
    name: "App",
    components: { Notifications },
};
</script>

<style lang="scss" src="./styles/all.scss"></style>
