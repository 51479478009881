<template>
    <footer class="footer">
        <div class="footer__wrapper container">
            <div class="footer__top">
                <div class="footer__store-name">
                    <span class="txt">{{ store_name }}</span>
                </div>
                <div class="footer__category">
                    <ul class="footer__nav">
                        <li class="footer__nav-category" v-for="category in categories" :key="category.id" :class="{ is_subcategory: category.sub_categories.length }">
                            <div class="footer__nav-category-row" v-if="category.id !== 3">
                                <router-link class="footer__nav-item" :to="category.path" @click="scrollUp">
                                    <span>{{ category.name }}</span>
                                </router-link>
                            </div>
                        </li>
                    </ul>
                    <div class="footer__subcategory">
                        <div class="footer__subcategory-item" v-for="category in store_categories" :key="category.id" @click="filterByCategory(category.id)">
                            <span class="footer__subcategory-span">{{ category.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="footer__search">
                    <div class="footer__search-wrapper">
                        <input class="footer__search-input" type="search" :maxlength="limit" enterkeyhint="Search" placeholder="Search..." @keyup.enter="search" v-model="search_value" />
                        <button class="footer__search-icon" @click="search">
                            <Icon :name="'searchIcon'" />
                        </button>
                        <button v-if="search_value" class="footer__clear-btn" @click="clearSearch"></button>
                    </div>
                    <div class="footer__user-icon" @click="onFavoriteClick">
                        <Icon name="heartIcon" />
                    </div>
                </div>
                <div class="footer__icons">
                    <template v-for="(url, icon_name) in social_links" :key="icon_name">
                        <SocialLink v-if="url" :name="icon_name" :url="url" />
                    </template>
                </div>
            </div>
            <div class="footer__bottom">
                <div class="footer__bottom-copyright">
                    <span>© 2024 by {{ store_name }}. </span>
                    <span> Powered by Merchr</span>
                </div>
                <nav class="footer__bottom-links">
                    <router-link class="footer__bottom-link footer__link" to="/terms-and-conditions">Terms and Conditions</router-link>
                    <router-link class="footer__bottom-link footer__link" to="/delivery">Delivery Information</router-link>
                    <router-link class="footer__bottom-link footer__link" to="/returns">Returns</router-link>
                    <router-link class="footer__bottom-link footer__link" to="/privacy">Privacy Policy</router-link>
                </nav>
            </div>
        </div>
    </footer>
</template>

<script src="./orbitFooter.js" />
<style lang="scss" scoped src="./orbitFooter.scss" />
