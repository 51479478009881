import { mapGetters } from "vuex";
import Icon from "@/components/ui/icon/icon.vue";
import CustomButton from "@/components/ui/customButton/customButton.vue";
import SocialLink from "@/components/ui/socialLink/socialLink.vue";

export default {
    name: "Banner",
    components: {
        Icon,
        CustomButton,
        SocialLink,
    },
    props: {
        is_banner: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        scrollToElement() {
            if (this.$route.path !== "/shop") {
                this.$router.push("/shop");
            }

            setTimeout(() => {
                const el = document.getElementById("productsList");

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({ behavior: "smooth" });
                }
            }, 600);
        },
    },
    computed: {
        ...mapGetters("storeProps", {
            themeType: "getThemeType",
            logoURL: "getLogoURL",
            store_settings: "getStoreSettings",
            bannerURL: "getBannerURL",
            title: "getTitle",
            social_links: "getSocialLinks",
        }),
        bannerSource() {
            return this.bannerURL || this.store_settings.bannerURL || this.store_settings.store_banner_image;
        },
        isVenture() {
            return this.themeType === "venture";
        },
        isOrbit() {
            return this.themeType === "orbit";
        },
    },
};
