export default {
    name: "Checkbox",
    props: {
        name: {
            type: String,
            defaultValue: "",
        },
        state: {
            type: Boolean,
            defaultValue: true,
        },
        is_invalid: {
            type: Boolean,
            defaultValue: false,
        }
    },
    methods: {
        onCheckboxChange({ target: { checked } }) {
            this.$emit("onChange", checked);
        },
    },
    emits: {
        onChange: null,
    },
};
