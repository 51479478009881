<template>
    <div class="about" v-if="about_text">
        <div class="about__img" v-if="about_image_URL || store_settings.store_about_image">
            <img :src="about_image_URL || store_settings.store_about_image" alt="about image" />
        </div>
        <div class="about__wrapper" :class="{ 'about__wrapper--wide': isWideLayout }">
            <div class="about__description" :class="{ 'about__description--wide': isWideLayout }">
                <div class="about__content" :class="{ 'about__content--center': isWideLayout }">
                    <div class="about__title title--02">About</div>
                    <div class="about__text" v-html="about_text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./About.js"></script>
<style lang="scss" scoped src="./About.scss" />
