<template>
    <div class="checkout-summary">
        <h2 class="checkout-summary__title title--03">Order Summary</h2>
        <!-- <div class="checkout-summary__payment-title">
            <span>PayPal Checkout</span>
            <img class="checkout-summary__payment-image" src="@/assets/images/PayPal_with_icon.png" alt="PayPal Logo" />
        </div> -->
        <!-- <div class="checkout-summary__payment-description">Accept PayPal, PayPal Credit and alternative payment types.</div> -->
        <div class="checkout-summary__total-container">
            <div class="checkout-summary__subtotal">
                <div class="checkout-summary__label">Subtotal</div>
                <div class="checkout-summary__price">{{ store_settings.currency.symbol }}{{ parseCost(subtotal_price) }}</div>
            </div>
            <div class="checkout-summary__shipping">
                <div class="checkout-summary__label">Shipping</div>
                <div class="checkout-summary__option">{{ store_settings.currency.code === "GBP" ? "Standard up to 5 Working Days" : "Standard up to 7 Working Days" }}</div>
                <div class="checkout-summary__price">{{ store_settings.currency.symbol }}{{ parseCost(shipping) }}</div>
            </div>
            <div class="checkout-summary__buttons">
                <!-- <button class="checkout-summary__btn" @click="closeFormCreditCard">PayPal</button> -->
                <div class="checkout-summary__btn">
                    <Icon class="checkout-summary__icon" name="'wallet'" />
                    <span>Debit / Credit Card</span>
                </div>
            </div>
            <form class="checkout-summary__form">
                <div class="checkout-summary__card">
                    <div class="checkout-summary__card-number">
                        <label class="checkout-summary__label" for="card-number">Card Number <span class="checkout-summary__star">*</span></label>
                        <div
                            class="checkout-summary__input"
                            id="card-number"
                            :class="{ 'checkout-summary__input-error': errors.cardNumber && (errors.cardNumber.empty || !errors.cardNumber.valid) }"
                        ></div>
                        <div class="mr-form-group-errors" v-if="errors.cardNumber && errors.cardNumber.empty">
                            <span>Field is required</span>
                        </div>
                        <div class="mr-form-group-errors" v-if="errors.cardNumber && !errors.cardNumber.valid && !errors.cardNumber.empty">
                            <span>Your card number is incorrect</span>
                        </div>
                    </div>
                    <div class="checkout-summary__card-details">
                        <div class="checkout-summary__card-date">
                            <label class="checkout-summary__label" for="card-date">Expiry Date <span class="checkout-summary__star">*</span></label>
                            <div
                                class="checkout-summary__input"
                                id="card-date"
                                :class="{ 'checkout-summary__input-error': errors.cardExpiry && (errors.cardExpiry.empty || !errors.cardExpiry.valid) }"
                            ></div>
                            <div class="mr-form-group-errors" v-if="errors.cardExpiry && errors.cardExpiry.empty">
                                <span>Field is required</span>
                            </div>
                            <div class="mr-form-group-errors" v-if="errors.cardExpiry && !errors.cardExpiry.valid && !errors.cardExpiry.empty">
                                <span>Your card number is incorrect</span>
                            </div>
                        </div>
                        <div class="checkout-summary__card-cvc">
                            <label class="checkout-summary__label" for="card-cvc">Card Code <span class="checkout-summary__star">*</span></label>
                            <div class="checkout-summary__input" id="card-cvc" :class="{ 'checkout-summary__input-error': errors.cardCvc && (errors.cardCvc.empty || !errors.cardCvc.valid) }"></div>
                            <div class="mr-form-group-errors" v-if="errors.cardCvc && errors.cardCvc.empty">
                                <span>Field is required</span>
                            </div>
                            <div class="mr-form-group-errors" v-if="errors.cardCvc && !errors.cardCvc.valid && !errors.cardCvc.empty">
                                <span>Your card number is incorrect</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="checkout-summary__billing">
                    <div class="checkout-summary__billing-title">Billing Address</div>
                    <div class="checkout-summary__billing-person">
                        <div class="checkout-summary__billing-first-name">
                            <label class="checkout-summary__label" for="billing-first-name">First name</label>
                            <input class="checkout-summary__input" id="billing-first-name" placeholder="First name" autocomplete="given-name" :model="new_credit_card.first_name" />
                        </div>
                        <div class="checkout-summary__billing-last-name">
                            <label class="checkout-summary__label" for="billing-last-name">Last name</label>
                            <input class="checkout-summary__input" id="billing-last-name" placeholder="Last name" autocomplete="family-name" :model="new_credit_card.last_name" />
                        </div>
                    </div>
                    <input class="checkout-summary__input" id="billing-first-address" placeholder="Address line 1" :model="new_credit_card.first_address" />
                    <input class="checkout-summary__input" id="billing-second-address" placeholder="Address line 2" :model="new_credit_card.second_address" />
                    <input class="checkout-summary__input" id="billing-city" placeholder="Town/City" :model="new_credit_card.city" />
                    <input class="checkout-summary__input" id="billing-postcode" placeholder="Postcode" :model="new_credit_card.postcode" />
                    <div class="checkout-summary__billing-mobile">
                        <label class="checkout-summary__label" for="billing-mobile">Mobile</label>
                        <input class="checkout-summary__input" id="billing-mobile" placeholder="+44" :model="new_credit_card.mobile" />
                    </div>
                    <div class="checkout-summary__policy">
                        You acknowledge the <a class="checkout-summary__billing-policy-link" href="https://pod.mymerchr.com/privacy-policy/">terms</a> of the service PayPal provides to the seller and
                        agree to the <a class="checkout-summary__billing-policy-link" href="https://pod.mymerchr.com/privacy-policy/">Privacy Statement.</a> No PayPal account required.
                    </div>
                </div> -->
            </form>
            <div class="checkout-summary__total">
                <div class="checkout-summary__total-label">Total</div>
                <div class="checkout-summary__total-price">
                    <!--                    <span class="checkout-summary__vat">(includes {{ store_settings.currency.symbol }}2.00 VAT)</span>-->
                    {{ store_settings.currency.symbol }}<span>{{ parseCost(round(subtotal_price + shipping)) }}</span>
                </div>
            </div>
            <!-- <div class="checkout-summary__payment-powered">
                <span>Powered by</span>
                <img class="checkout-summary__payment-image" src="@/assets/images/PayPal.png" alt="PayPal Logo" />
            </div> -->
        </div>
        <div class="checkout-summary__policy">
            Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our
            <router-link class="checkout-summary__policy-link" to="/privacy">privacy policy</router-link>.
        </div>
        <div class="checkout-summary__terms-conditions">
            <div class="checkout-summary__terms-conditions-inner">
                <Checkbox :state="terms_conditions" :is_invalid="errors.terms_conditions && !errors.terms_conditions.valid" @onChange="changeTermsConditions" />
                <span
                    >I have read and agree to the website <router-link class="checkout-summary__terms-conditions-link" to="/terms-and-conditions">terms and conditions</router-link>
                    <span class="checkout-summary__star">*</span></span
                >
            </div>
            <div class="mr-form-group-errors" v-if="errors.terms_conditions && !errors.terms_conditions.valid">
                <span>Field is required</span>
            </div>
        </div>
    </div>
</template>
<script src="./summary.js" />
<style src="./summary.scss" lang="scss" scoped />
