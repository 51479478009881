<template>
    <div class="orbit-product-card" :class="{ 'orbit-product-card--not-active': is_not_active }">
        <RouterLink v-if="!is_not_active" :to="'/product/' + part_url" class="orbit-product-card__link" @click="onLinkClick" />
        <div class="orbit-product-card__options">
            <button class="orbit-product-card__favorite" :class="{ 'orbit-product-card__favorite--full': is_in_favorite }" @click.stop="handleFavoriteClick">
                <Icon :name="is_in_favorite ? 'iconFavoriteFull' : 'iconFavorite'" />
            </button>
            <span class="orbit-product-card__label" v-if="label === 'Personalise'">{{ label }}</span>
        </div>
        <div class="orbit-product-card__img">
            <img :src="store_product_image_path" :alt="product_name" />
            <CustomButton
                v-if="!is_not_active"
                class="orbit-product-card__btn"
                :text="type === 'variable' && !is_has_editable_text_area ? 'Select options' : is_has_editable_text_area ? 'Personalise' : 'Add To Bag'"
                @onClick="handleAddToCart"
                :is_disabled="is_disabled"
            />
        </div>
        <div class="orbit-product-card__description">
            <span class="orbit-product-card__title">{{ product_name }}</span>
            <span v-if="!in_stock" class="orbit-product-card__stock-status">Out of stock</span>
            <span class="orbit-product-card__cost">{{ currency.symbol }}{{ parseCost(selling_price) }}</span>
        </div>
        <div class="orbit-product-card__footer">
            <span class="orbit-product-card__footer-message" v-if="is_not_active">Product is unavailable</span>
        </div>
    </div>
</template>
<script src="./orbitProductCard.js" />
<style src="./orbitProductCard.scss" lang="scss"></style>
