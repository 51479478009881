import EmptyState from "@/components/shared/emptyState/emptyState.vue";
import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/components/shared/productCard/productCard.vue";
import CartService from "@/services/cart.service";
import Preloader from "@/components/shared/preloader/preloader.vue";
const cartService = new CartService();
export default {
    name: "FavoriteView",
    components: { Preloader, ProductCard, EmptyState },
    data: () => ({
        list_with_active_products: [],
        is_loading: true,
    }),
    methods: {
        ...mapActions("products", [
            "setProductToFavorite",
            "fetchAvailableProducts",
            "removeProductFromFavorite",
            "fetchFavoriteProducts",
            "setProductToCart",
            "updateProductInCart",
            "updateFavoriteProducts",
        ]),
        async onAddToCart(prod) {
            const { product, count, is_available } = this.products_in_cart_hash[prod.id] || {};

            if (this.products_in_cart_hash[prod.id]) {
                await this.updateProductInCart({ product, count: count + 1, is_available });
            } else {
                await this.setProductToCart({ product: prod });
            }

            this.$notify({
                title: "Product was added to bag!",
                type: "success",
            });
        },
        async onRemove(product) {
            await this.removeProductFromFavorite(product.id);
        },
    },
    computed: {
        ...mapGetters("products", {
            favoriteProducts: "getFavoriteProducts",
            products_in_cart_hash: "getProductsInCartHash",
        }),
        ...mapGetters("storeProps", {
            storeSettings: "getStoreSettings",
        }),
    },
    async mounted() {
        await this.fetchFavoriteProducts();
        await this.fetchAvailableProducts();
        const parsed_favorite_product_list = this.favoriteProducts.map(({ id, combination, store_product_variant_id }) => ({
            id,
            combination: combination || null,
            store_product_variant_id: store_product_variant_id || null,
        }));
        const {
            data: { store_products_available, store_products_unavailable },
        } = await cartService.postProducts({ store_products: parsed_favorite_product_list });

        const updated_list_with_favorite_products = this.favoriteProducts.reduce((acc, current) => {
            const the_same_product_from_response = store_products_available.find(sp => sp.id === current.id);
            if (the_same_product_from_response) {
                acc.push({
                    ...current,
                    ...the_same_product_from_response,
                    store_product_image_path: the_same_product_from_response.store_product_image_url,
                    product_name: the_same_product_from_response.store_product_name,
                    is_available: !!the_same_product_from_response,
                });
            } else {
                const is_in_unavailable = store_products_unavailable.find(sp => sp.id === current.id);

                if (is_in_unavailable?.store_product_image_url) {
                    acc.push({
                        ...current,
                        ...is_in_unavailable,
                        product_name: is_in_unavailable.store_product_name,
                        store_product_image_path: is_in_unavailable.store_product_image_url,
                        is_available: false,
                    });
                }
            }
            return acc;
        }, []);

        this.list_with_active_products = updated_list_with_favorite_products;
        await this.updateFavoriteProducts(updated_list_with_favorite_products);
        this.is_loading = false;
    },
};
