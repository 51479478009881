<template>
    <div class="stride-product-card" :class="{ 'stride-product-card--not-active': is_not_active }">
        <RouterLink v-if="!is_not_active" :to="'/product/' + part_url" class="stride-product-card__link" @click="onLinkClick" />
        <button class="stride-product-card__favorite" :class="{ 'stride-product-card__favorite--full': is_in_favorite }" @click="handleFavoriteClick">
            <Icon :name="is_in_favorite ? 'iconFavoriteFull' : 'iconFavorite'" />
        </button>
        <span class="stride-product-card__label" v-if="label === 'Personalise'">{{ label }}</span>
        <figure class="stride-product-card__img">
            <img :src="store_product_image_path" :alt="product_name" />
        </figure>
        <div class="stride-product-card__description">
            <span class="stride-product-card__title">{{ product_name }}</span>
            <span v-if="!in_stock" class="stride-product-card__stock-status">Out of stock</span>
            <span class="stride-product-card__cost">{{ currency.symbol }}{{ parseCost(selling_price) }}</span>
        </div>
        <div class="stride-product-card__footer">
            <span class="stride-product-card__footer-message" v-if="is_not_active">Product is unavailable</span>
            <CustomButton
                v-if="!is_not_active"
                :text="type === 'variable' && !is_has_editable_text_area ? 'Select options' : is_has_editable_text_area ? 'Personalise' : 'Add To Bag'"
                @onClick="handleAddToCart"
                :is_disabled="is_disabled"
            />
        </div>
    </div>
</template>
<script src="./strideProductCard.js" />
<style src="./strideProductCard.scss" lang="scss"></style>
