<template>
    <div class="returns">
        <div class="returns__wrapper">
            <h2 class="returns__title title--02">Returns</h2>
            <div class="returns__content">
                <div class="returns__content-column returns__content-column-left">
                    <p>
                        We hope that you will be satisfied with your Products but there may be occasions where you feel it necessary to return an item. We aim to keep the process as simple as
                        possible.
                    </p>
                    <p>
                        All of our products are being printed just for you, so if you change your mind about an item once it's been printed unfortunately we are unable to offer a refund. Of course if
                        the item is faulty, does not meet the description or match your instructions then get in touch and we can arrange a return.
                    </p>
                    <p>We will try to attend to all return requests as soon as practically possible. Currently, return requests will be processed in up to 14 days.</p>
                    <p>Please note that returns are not able to be accepted in person at our registered office address.</p>
                </div>
                <div class="returns__content-column returns__content-column-right">
                    <p>
                        <strong>To return an item:</strong>
                    <ol>
                        <li>Fill out the form below to detail why your product is faulty or has not matched the description.</li>
                        <li>Return your product to: Merchr Returns, Unit 6 Rhodes Business Park, Silburn Way, Manchester, M24 4NE (please include your order number within the package).</li>
                        <li>Once your product has been received, it will be inspected for return eligibility.</li>
                        <li>When return eligibility has been confirmed, you will receive an email confirmation and then the refund will be processed.</li>
                    </ol>
                    </p>
                </div>
            </div>
            <div class="returns__form">
                <h2 class="contact__title title--02">Contact us</h2>
                <ContactUsForm :with_order_id="true" buttonText="Request Return" />
            </div>
        </div>
    </div>
</template>

<script src="./ReturnsView.js"></script>
<style lang="scss" scoped src="./ReturnsView.scss" />
