import ApiService from "@/services/index";
import IndexedDbService from "@/services/indexedDB.service";
import store from "@/store/index";
import router from '@/router';

const GET_PROPS_URL = "../productsList.json";

const apiService = new ApiService();
const indexDBService = new IndexedDbService();

export const getProducts = async ({ pagination, filter = {} } = {}) => {
    const url = store.getters["storeProps/getPreviewMode"] ? GET_PROPS_URL : `${process.env.VUE_APP_API_HOST_NAME}/api/website/store`;
    return await apiService.get({ url: url, params: { ...pagination, ...filter ,type: "store_product_list" } });
};

export const setToFavoriteProducts = async products => {
    return await indexDBService.set("favoriteProducts", products);
};

export const getToFavoriteProducts = async () => {
    return await indexDBService.get("favoriteProducts");
};

export const setProductToCart = async products => {
    return await indexDBService.set("cart", products);
};

export const getCartProducts = async () => {
    return await indexDBService.get("cart");
};

export const getProductData = async ({ part_url } = {}) => {
    const hostname = process.env.VUE_APP_TEST_HOST_NAME || window.location.hostname.split(".")[0];
    const url = `${process.env.VUE_APP_API_HOST_NAME}/api/website/store`;
    return await apiService.get({ url: url, params: { type: "basic_store_product", url: `https://${hostname}.mymerchr.com/product/${part_url}` } });
};

export const getProductIndex = async (productsInCart, store_product) => {
    let product_is_in_cart_index = null;
    if (store_product.type === "variable") {

        product_is_in_cart_index = productsInCart.findIndex(({ product }) => {
            const { colour, text, font } = product.meta_data?.customisation || {};
            const { text: productText, colour: productColour, font: productFont } = store_product.meta_data?.customisation || {};
            const check_equal_text_params = store_product.personalise_state
                ? productColour === colour && text === productText && font === productFont
                : [colour, text, font].every((v) => !v);
            return (
                product.id === store_product.id &&
                product.store_product_variant_id === store_product.store_product_variant_id &&
                product.combination === store_product.combination &&
                check_equal_text_params
            );
        });
    }
    if (store_product.type === "simple") {
        product_is_in_cart_index = productsInCart.findIndex(({ product }) => {
            const { colour, text, font } = product.meta_data?.customisation || {};
            const { text: productText, colour: productColour, font: productFont } = store_product.meta_data?.customisation || {};
            const check_equal_text_params = store_product.personalise_state
                ? productColour === colour && text === productText && font === productFont
                : [colour, text, font].every((v) => !v);
            return (
                product.id === store_product.id &&
                check_equal_text_params
            );
        });
    }

    return product_is_in_cart_index;
};

export const setFilterQueryParams = (filter) => {
    let params = [];
    let route = '/shop'
    if (filter.search) {
        params.push(`search=${filter.search}`);
    }

    if (filter.order_by) {
        params.push(`order_by=${filter.order_by}`);
    }

    if (filter.product_category_ids.length) {
        params.push(`categories=${filter.product_category_ids.join(',')}`);
    }

    if (params && params.length) {
        route += `?${params.join('&')}`;
    }
    router.push(route);
};
