<template>
    <div class="delivery">
        <div class="delivery__wrapper">
            <h2 class="delivery__title title--02">Delivery Information</h2>
            <div class="delivery__content">
                <div class="delivery__content-column delivery__content-column-left">
                    <div class="delivery__content-title title--03">Standard Delivery</div>
                    <div class="entry-content" itemprop="mainContentOfPage" itemscope="" itemtype="https://schema.org/WebPageElement">
                        <div class="entry-content">
                            <p><b>For deliveries to the United Kingdom, please allow up to 5 working days for your order to be delivered.</b></p>
                            <p><b>For deliveries to other locations, please allow up to 7 working days for your order to be delivered.</b></p>
                        </div>

                        <ul>
                            <li>Your delivery price will be calculated during checkout.</li>
                            <li>Deliveries over peak periods may take slightly longer.</li>
                            <li class="entry-content">Sorry but we may not process or deliver orders on public holidays and weekends so please bear this in mind when ordering.</li>
                            <li class="entry-content">
                                Unfortunately we do not deliver to PO Box or BFPO addresses. Deliveries to the Scottish Highlands &amp; Islands, the Isle of Wight, the Isle of Man and Northern Ireland
                                may take longer.
                            </li>
                        </ul>

                        <p>Currently, we ship to the following countries:</p>

                        <p>
                            <strong>Europe: </strong>Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Gibraltar, Greece, Hungary, Ireland,
                            Italy, Latvia, Lithuania, Luxembourg, Malta, Monaco, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, Switzerland &amp; United Kingdom.
                        </p>
                        <p><strong>North America:</strong> United States (excl. HI) &amp; Canada.</p>

                        <ul>
                            <li>
                                Parcels will normally be sent by your national postal service. For larger orders (between 2.5kg and 20kg), we will use a courier, which may require a signature upon
                                delivery.
                            </li>
                            <li>Please note that for orders containing multiple items, products may come from separate warehouses and therefore you may receive a split delivery.</li>
                        </ul>

                        <p>For any delivery enquiries, please contact the Merchr Support Team below:</p>
                    </div>
                </div>
                <div class="delivery__content-column delivery__content-column-right">
                    <div class="delivery__content-title title--03">Packaging</div>
                    <p>
                        Your products are packaged in the most secure yet minimal way possible to reduce waste and help meet our carbon neutral target date of 2025. Most orders will arrive in one of
                        our beautiful shiny envelopes or recycled cardboard box.
                    </p>
                </div>
            </div>
            <div class="delivery__form">
                <h2 class="contact__title title--02">Contact us</h2>
                <ContactUsForm />
            </div>
        </div>
    </div>
</template>

<script src="./DeliveryView.js"></script>
<style lang="scss" scoped src="./DeliveryView.scss" />
