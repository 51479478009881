<template>
    <VentureProductCard
        v-if="themeType === 'venture'"
        :in_stock="in_stock"
        :store_product_image_path="store_product_image_path"
        :product_name="product_name"
        :selling_price="selling_price"
        :label="label"
        :is_in_favorite="is_in_favorite"
        :currency="currency"
        :id="id"
        :is_disabled="is_disabled"
        :part_url="part_url"
        :type="type"
        :is_has_editable_text_area="is_has_editable_text_area"
        :is_not_active="is_not_active"
        :reload_page="reload_page"
        @onFavoriteClick="handleFavoriteClick"
        @onAddToCart="handleAddToCart"
        @onLinkClick="onLinkClick"
    />
    <StrideProductCard
        v-if="themeType === 'stride'"
        :in_stock="in_stock"
        :store_product_image_path="store_product_image_path"
        :product_name="product_name"
        :selling_price="selling_price"
        :label="label"
        :is_in_favorite="is_in_favorite"
        :currency="currency"
        :id="id"
        :description="description"
        :is_disabled="is_disabled"
        :part_url="part_url"
        :type="type"
        :is_has_editable_text_area="is_has_editable_text_area"
        :is_not_active="is_not_active"
        :reload_page="reload_page"
        @onFavoriteClick="handleFavoriteClick"
        @onAddToCart="handleAddToCart"
        @onLinkClick="onLinkClick"
    />
    <OrbitProductCard
        v-if="themeType === 'orbit'"
        :in_stock="in_stock"
        :store_product_image_path="store_product_image_path"
        :product_name="product_name"
        :selling_price="selling_price"
        :label="label"
        :is_in_favorite="is_in_favorite"
        :currency="currency"
        :id="id"
        :is_disabled="is_disabled"
        :part_url="part_url"
        :type="type"
        :is_has_editable_text_area="is_has_editable_text_area"
        :is_not_active="is_not_active"
        :reload_page="reload_page"
        @onFavoriteClick="handleFavoriteClick"
        @onAddToCart="handleAddToCart"
        @onLinkClick="onLinkClick"
    />
</template>
<script src="./productCard.js"></script>
